import { HStack, Image, StackProps, Text, VStack } from "@chakra-ui/react";
import { USER_FALLBACK_URL } from "helpers/constants/url-constants";
import { isNil } from "lodash/fp";
import { IUser } from "services/resources/firebase/user/types.d";
import { zubeColors } from "styles/colors";
import { DisplayType } from "../types.d";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";

interface IUserCardProps extends StackProps {
  user: IUser;
  showRole?: boolean;
  displayType?: DisplayType;
  showRightIcon?: boolean;
  isHovered?: boolean;
}

export const UserCard: React.FC<IUserCardProps> = (props): JSX.Element => {
  const {
    user,
    showRole = false,
    displayType = "full",
    showRightIcon = false,
    isHovered = false,
    ...rest
  } = props;
  const userData = useSelector(getUser);

  return (
    <HStack {...rest}>
      <HStack spacing={5} w="100%" h="100%">
        <Image
          borderRadius="full"
          boxSize="50px"
          src={!isNil(user.img) ? user.img : USER_FALLBACK_URL}
        />
        {displayType === "full" ? (
          <>
            {showRole ? (
              <VStack alignItems="flex-start" h="100%">
                <Text color="black">{user.fullName}</Text>
                <Text
                  color={!isHovered ? "black" : zubeColors.adminRed}
                  fontSize={10}
                >
                  {userData.isAdmin ? "Administrador" : "Partner"}
                </Text>
              </VStack>
            ) : (
              <Text>{user.fullName}</Text>
            )}
            {showRightIcon ? (
              <IoIosArrowDown
                style={{ position: "absolute", right: -10 }}
                color={
                  !isHovered ? zubeColors.zubeGray.dark : zubeColors.adminRed
                }
              />
            ) : null}
          </>
        ) : null}
      </HStack>
    </HStack>
  );
};

UserCard.defaultProps = {
  justifyContent: "center",
  alignItems: "center",
};
