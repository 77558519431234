import {
  Box,
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import {
  DashboardPanel,
  DashboardTable,
  PageContainer,
} from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { userResources } from "services/resources/firebase/user";
import { IUser } from "services/resources/firebase/user/types.d";

export const Users: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);
  const isSmallScreen = useSmallScreen();
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);

  const columns: ITypedListTableColumn<IUser>[] = [
    {
      accessorKey: "img",
      header: "Imagen",
      cell: ({ row }) => (
        <Box maxH="70px">
          {!isNil(row.original.img) ? (
            <Image src={row.original.img} h="70px" />
          ) : (
            <Text>Sin imagen</Text>
          )}
        </Box>
      ),
    },
    {
      accessorKey: "fullName",
      header: "Nombre",
      cell: ({ row }) => <Text>{row.original.fullName}</Text>,
    },
    {
      accessorKey: "email",
      header: "Email",
      cell: ({ row }) => <Text>{row.original.email}</Text>,
    },
    {
      header: "Opciones",
      cell: () => (
        <VStack alignItems="flex-start">
          <Button
            isDisabled
            color="white"
            bg="pink"
            rounded="full"
            fontSize={12}
          >
            Editar
          </Button>
          <Button
            isDisabled
            color="white"
            bg="red"
            rounded="full"
            fontSize={12}
          >
            Eliminar
          </Button>
        </VStack>
      ),
    },
  ];

  const handleOnUsersGet = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await userResources.getAll(token as string);
      if (!isNil(data)) {
        // filter out deleted users (i.e. info swiped)
        setUsers(data.filter((user) => user.email));
      }
    } catch (e) {
      console.log("Error-handleOnUsersGet: ", e);
    }
    setLoadingModalVisible(false);
  };

  useEffect(() => {
    handleOnUsersGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer loadingModalVisible={loadingModalVisible}>
      {isSmallScreen ? (
        <VStack alignItems="center" justifyContent="center" my={5}>
          <Grid gap={5} templateColumns="repeat(1, 1fr)" w="90%">
            {users.map((user, index) => (
              <GridItem key={index}>
                <DashboardPanel
                  label={user.fullName}
                  rightElement={<Image src={user.img} h="30px" />}
                >
                  <VStack w="100%">
                    <Divider />
                    <HStack fontSize={12} spacing="auto" my={3} w="90%">
                      <Text fontWeight="bold" w="35%">
                        Email
                      </Text>
                      <Text>{user.email}</Text>
                    </HStack>
                    <Divider />
                    <HStack>
                      <Button
                        color="white"
                        bg="pink"
                        rounded="full"
                        fontSize={12}
                      >
                        Editar
                      </Button>
                      <Button
                        color="white"
                        bg="red"
                        rounded="full"
                        fontSize={12}
                      >
                        Eliminar
                      </Button>
                    </HStack>
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      ) : (
        <Center bg="transparent" w="100%" h="100%" alignSelf="center">
          <Center bg="white" w="90%" h="90%" borderRadius={5}>
            {columns.length > 0 ? (
              <DashboardTable
                data={users}
                columns={columns as ColumnDef<object>[]}
                w="90%"
                h="90%"
                py={5}
              />
            ) : null}
          </Center>
        </Center>
      )}
    </PageContainer>
  );
};
