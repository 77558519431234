import {
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BiChevronDown } from "react-icons/bi";
import { DashboardPanel, PageContainer } from "components/elements";
import { isEmpty, isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "redux-service/slices";
import { driverRequestResources } from "services/resources/firebase/driver-request/driver-request";
import {
  DriverRequestDocumentTypeEnum,
  DriverTypeEnum,
  IRequestWithDriver,
  IVerifyingDocument,
  VerificationStatusEnum,
} from "services/resources/firebase/driver-request/types.d";
import { userResources } from "services/resources/firebase/user";
import { zubeColors } from "styles/colors";
import { unitResources } from "services/resources/firebase/unit";
import { UnitStatusEnum } from "services/resources/firebase/unit/types.d";
import { useSmallScreen } from "hooks/layout/dimension-hooks";

export const ReviewRequest: React.FC = (): JSX.Element => {
  const { requestId } = useParams();
  const { token } = useSelector(getUser);
  const isSmallScreen = useSmallScreen();
  const navigate = useNavigate();
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [requestWithDriver, setRequestWithDriver] =
    useState<IRequestWithDriver>({} as IRequestWithDriver);

  const retrieveDriverRequest = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data: requestData } = await driverRequestResources.getById(
        requestId as string,
        token as string
      );
      const { data: userData } = await userResources.getById(
        requestId as string,
        token as string
      );
      if (!isNil(requestData) && !isNil(userData)) {
        setRequestWithDriver({
          request: requestData,
          driver: userData,
        });
      }
    } catch (e) {
      console.log("Error-retrieveDriverRequests: ", e);
      window.alert("Ocurrió un error inesperado.");
    } finally {
      setLoadingModalVisible(false);
    }
  };

  const patchDocument = async (
    type: DriverRequestDocumentTypeEnum,
    newStatus?: VerificationStatusEnum,
    newComment?: string
  ): Promise<void> => {
    try {
      switch (type) {
        case DriverRequestDocumentTypeEnum.controlCard:
          await driverRequestResources.patchControlCard(
            {
              ...(requestWithDriver.request
                .controlCard as IVerifyingDocument<string>),
              comment:
                newComment ||
                (
                  requestWithDriver.request
                    .controlCard as IVerifyingDocument<string>
                ).comment,
              status:
                newStatus ||
                (
                  requestWithDriver.request
                    .controlCard as IVerifyingDocument<string>
                ).status,
            },
            requestId as string,
            token as string
          );
          break;
        case DriverRequestDocumentTypeEnum.idCard:
          await driverRequestResources.patchIdCard(
            {
              ...requestWithDriver.request.idCard,
              comment: newComment || requestWithDriver.request.idCard.comment,
              status: newStatus || requestWithDriver.request.idCard.status,
            },
            requestId as string,
            token as string
          );
          break;
        case DriverRequestDocumentTypeEnum.driverLicense:
          await driverRequestResources.patchDriverLicense(
            {
              ...requestWithDriver.request.driverLicense,
              comment:
                newComment || requestWithDriver.request.driverLicense.comment,
              status:
                newStatus || requestWithDriver.request.driverLicense.status,
            },
            requestId as string,
            token as string
          );
          break;
        case DriverRequestDocumentTypeEnum.insurance:
          await driverRequestResources.patchInsurance(
            {
              ...requestWithDriver.request.insurance,
              comment:
                newComment || requestWithDriver.request.insurance.comment,
              status: newStatus || requestWithDriver.request.insurance.status,
            },
            requestId as string,
            token as string
          );
          break;
        case DriverRequestDocumentTypeEnum.circulationCard:
          await driverRequestResources.patchCirculationCard(
            {
              ...(requestWithDriver.request
                .circulationCard as IVerifyingDocument<string>),
              comment:
                newComment ||
                (
                  requestWithDriver.request
                    .circulationCard as IVerifyingDocument<string>
                ).comment,
              status:
                newStatus ||
                (
                  requestWithDriver.request
                    .circulationCard as IVerifyingDocument<string>
                ).status,
            },
            requestId as string,
            token as string
          );
          break;
        case DriverRequestDocumentTypeEnum.vehicleInfo:
          await driverRequestResources.patchVehicleInfo(
            {
              ...requestWithDriver.request.vehicleInfo,
              comment:
                newComment || requestWithDriver.request.vehicleInfo.comment,
              status: newStatus || requestWithDriver.request.vehicleInfo.status,
            },
            requestId as string,
            token as string
          );
          break;
        default:
          break;
      }
    } catch (e) {
      window.alert("Ocurrió un error al actualizar el documento.");
      console.error("ReviewRequest-patchDocument", e);
    } finally {
      retrieveDriverRequest();
    }
  };

  const patchGlobalStatus = async (
    newStatus: VerificationStatusEnum
  ): Promise<void> => {
    try {
      await driverRequestResources.patchRequestStatus(
        newStatus,
        requestId as string,
        token as string
      );
    } catch (e) {
      window.alert("Ocurrió un error al actualizar la solicitud.");
      console.error("ReviewRequest-patchGlobalStatus", e);
    } finally {
      navigate("/requests");
    }
  };

  const enableDriver = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      // set request as completed
      await driverRequestResources.patchRequestStatus(
        VerificationStatusEnum.COMPLETED,
        requestId as string,
        token as string
      );
      // update driver verified flag and update score
      await userResources.enableDriver(requestId as string, token as string);
      // create unit doc with form payload
      await unitResources.create(
        {
          driver: requestId as string,
          brand: requestWithDriver.request.vehicleInfo.form.brand,
          model: requestWithDriver.request.vehicleInfo.form.model,
          plates: requestWithDriver.request.vehicleInfo.form.plates,
          year: Number(requestWithDriver.request.vehicleInfo.form.year),
          image: requestWithDriver.request.vehicleInfo.document,
          status: UnitStatusEnum.PENDING,
        },
        token as string
      );
    } catch (e) {
      window.alert("Ocurrió un error al habilitar al conductor.");
      console.error("ReviewRequest-enableDriver", e);
    } finally {
      navigate("/requests");
    }
  };

  const getDocList = () => {
    switch (requestWithDriver.request.driverType.field) {
      case DriverTypeEnum.PUBLIC:
        return [
          requestWithDriver.request.idCard,
          requestWithDriver.request.driverLicense,
          requestWithDriver.request.circulationCard,
          requestWithDriver.request.insurance,
          requestWithDriver.request.controlCard,
        ];
      case DriverTypeEnum.PRIVATE:
        return [
          requestWithDriver.request.idCard,
          requestWithDriver.request.driverLicense,
          requestWithDriver.request.insurance,
        ];
      default:
        return [];
    }
  };

  const getStatusLabel = (documentStatus: VerificationStatusEnum) => {
    switch (documentStatus) {
      case VerificationStatusEnum.PENDING:
        return "Pendiente";
      case VerificationStatusEnum.SUBMITTED:
        return "Pendiente";
      case VerificationStatusEnum.REJECTED:
        return "Rechazado";
      case VerificationStatusEnum.APPROVED:
        return "Aprobado";
      default:
        return "Selecciona el estatus";
    }
  };

  const getDocTypeByOrder = (
    index: number
  ): { label: string; type: DriverRequestDocumentTypeEnum } => {
    switch (index) {
      case 0:
        return { label: "INE", type: DriverRequestDocumentTypeEnum.idCard };
      case 1:
        return {
          label: "Licencia de Manejo",
          type: DriverRequestDocumentTypeEnum.driverLicense,
        };
      case 2:
        return {
          label: "Tarjeta de Circulación",
          type: DriverRequestDocumentTypeEnum.circulationCard,
        };
      case 3:
        return {
          label: "Póliza de Seguro",
          type: DriverRequestDocumentTypeEnum.insurance,
        };
      case 4:
        return {
          label: "Tarjeta de Control",
          type: DriverRequestDocumentTypeEnum.controlCard,
        };
      // case 5 (vehicle form) is manually handled since grid item layout differs
      default:
        return { label: "", type: DriverRequestDocumentTypeEnum.idCard };
    }
  };

  useEffect(() => {
    retrieveDriverRequest();
    return () => setRequestWithDriver({} as IRequestWithDriver);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer loadingModalVisible={loadingModalVisible}>
      {!isEmpty(requestWithDriver) ? (
        <VStack h="100%" alignItems="center" overflow="auto">
          <HStack justifyContent="flex-end" mt={5} w="90%">
            <Button
              bg="#ffa755"
              borderRadius={20}
              onClick={() => navigate("/requests")}
            >
              Regresar
            </Button>
          </HStack>
          <Grid
            gap={5}
            templateColumns={`repeat(${isSmallScreen ? 1 : 2}, 1fr)`}
            w="90%"
          >
            <GridItem>
              <DashboardPanel label="Datos Generales">
                <VStack w="100%">
                  <Divider mb={3} />
                  {[
                    {
                      label: "Nombre",
                      value: requestWithDriver.driver.fullName,
                    },
                    {
                      label: "Nombre corto",
                      value: "",
                    },
                    {
                      label: "Email",
                      value: requestWithDriver.driver.email,
                    },
                    {
                      label: "Teléfono",
                      value: requestWithDriver.driver.mobilePhoneNumber,
                    },
                    {
                      label: "Términos y condiciones",
                      value: "check",
                    },
                  ].map((item) => (
                    <>
                      <HStack fontSize={12} spacing="auto" my={3} w="90%">
                        <Text fontWeight="bold" w="35%">
                          {item.label}
                        </Text>
                        {item.value === "check" ? (
                          <Text>&#9989;</Text>
                        ) : (
                          <Text>{item.value}</Text>
                        )}
                      </HStack>
                      <Divider />
                    </>
                  ))}
                </VStack>
              </DashboardPanel>
            </GridItem>
            <GridItem>
              <DashboardPanel label="Datos Del Vehículo">
                <VStack w="100%">
                  <Divider mb={3} />
                  {[
                    {
                      label: "Modelo",
                      value: requestWithDriver.request.vehicleInfo.form.model,
                    },
                    {
                      label: "Fabricante",
                      value: requestWithDriver.request.vehicleInfo.form.brand,
                    },
                    {
                      label: "Año",
                      value: requestWithDriver.request.vehicleInfo.form.year,
                    },
                    {
                      label: "Placas",
                      value: requestWithDriver.request.vehicleInfo.form.plates,
                    },
                    {
                      label: "Código",
                      value:
                        requestWithDriver.request.vehicleCode ||
                        "En espera de código",
                    },
                  ].map((item) => (
                    <>
                      <HStack fontSize={12} spacing="auto" my={3} w="90%">
                        <Text fontWeight="bold" w="35%">
                          {item.label}
                        </Text>
                        <Text>{item.value}</Text>
                      </HStack>
                      <Divider />
                    </>
                  ))}
                </VStack>
              </DashboardPanel>
            </GridItem>
          </Grid>
          <HStack h="15%" bg="#aaa" borderRadius={16} my={5} w="90%">
            <Text p={5}>Revisión de documentos</Text>
          </HStack>
          <Grid
            gap={5}
            mb={5}
            templateColumns={`repeat(${isSmallScreen ? 1 : 2}, 1fr)`}
            w="90%"
          >
            {getDocList().map((doc, index) => (
              <GridItem key={index}>
                <DashboardPanel label={getDocTypeByOrder(index).label}>
                  <VStack w="100%" alignItems="flex-start">
                    <Text
                      bg="#aaa"
                      borderRadius={16}
                      color="white"
                      fontWeight="bold"
                      px={2}
                      py={1}
                    >
                      {`Fecha de expiración: ${
                        doc?.expirationDate.split("T")[0]
                      }`}
                    </Text>
                    <Divider />
                    {typeof doc?.document === "object" ? (
                      <HStack>
                        <Image src={doc.document.front} w="40%" />
                        <Image src={doc.document.back} w="40%" />
                      </HStack>
                    ) : (
                      <Image src={doc?.document} w="40%" />
                    )}
                    <Divider />
                    <Text fontWeight="bold">{`¿${
                      getDocTypeByOrder(index).label
                    } correcta?:`}</Text>
                    <Menu>
                      <MenuButton
                        as={Button}
                        rightIcon={<BiChevronDown />}
                        w="100%"
                      >
                        {getStatusLabel(
                          doc?.status || VerificationStatusEnum.PENDING
                        )}
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() =>
                            patchDocument(
                              getDocTypeByOrder(index).type,
                              VerificationStatusEnum.PENDING
                            )
                          }
                        >
                          Pendiente
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            patchDocument(
                              getDocTypeByOrder(index).type,
                              VerificationStatusEnum.REJECTED
                            )
                          }
                        >
                          Rechazado
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            patchDocument(
                              getDocTypeByOrder(index).type,
                              VerificationStatusEnum.APPROVED
                            )
                          }
                        >
                          Aprobado
                        </MenuItem>
                      </MenuList>
                    </Menu>
                    {doc?.status === VerificationStatusEnum.REJECTED ? (
                      <VStack alignItems="flex-start" w="100%">
                        <Text fontWeight="bold">Motivo del Rechazo:</Text>
                        <Input
                          onBlur={(e) => {
                            e.target.value &&
                              patchDocument(
                                getDocTypeByOrder(index).type,
                                undefined,
                                e.target.value
                              );
                          }}
                          placeholder={doc.comment}
                        />
                      </VStack>
                    ) : null}
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
            <GridItem>
              <DashboardPanel label="Foto del Carro">
                <VStack w="100%" alignItems="flex-start">
                  <Divider />
                  <Image
                    src={requestWithDriver.request.vehicleInfo.form.picture}
                    w="40%"
                  />
                  {[
                    {
                      label: "Modelo del vehículo",
                      value: requestWithDriver.request.vehicleInfo.form.model,
                    },
                    {
                      label: "Fabricante",
                      value: requestWithDriver.request.vehicleInfo.form.brand,
                    },
                    {
                      label: "Año del vehículo",
                      value: requestWithDriver.request.vehicleInfo.form.year,
                    },
                    {
                      label: "Placas del vehículo",
                      value: requestWithDriver.request.vehicleInfo.form.plates,
                    },
                  ].map((item) => (
                    <>
                      <HStack fontSize={12} spacing="auto" my={3} w="90%">
                        <Text fontWeight="bold" w="35%">
                          {item.label}
                        </Text>
                        <Text>{item.value}</Text>
                      </HStack>
                      <Divider />
                    </>
                  ))}
                  <Text fontWeight="bold">
                    ¿Los datos del vehículo son correctos?
                  </Text>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<BiChevronDown />}
                      w="100%"
                    >
                      {getStatusLabel(
                        requestWithDriver.request.vehicleInfo.status
                      )}
                    </MenuButton>
                    <MenuList>
                      <MenuItem>Pendiente</MenuItem>
                      <MenuItem
                        onClick={() =>
                          patchDocument(
                            DriverRequestDocumentTypeEnum.vehicleInfo,
                            VerificationStatusEnum.REJECTED
                          )
                        }
                      >
                        Rechazado
                      </MenuItem>
                      <MenuItem>Aprobado</MenuItem>
                    </MenuList>
                  </Menu>
                  {requestWithDriver.request.vehicleInfo.status ===
                  VerificationStatusEnum.REJECTED ? (
                    <VStack alignItems="flex-start" w="100%">
                      <Text fontWeight="bold">Motivo del Rechazo:</Text>
                      <Input
                        onBlur={(e) => {
                          e.target.value &&
                            e.target.value !==
                              requestWithDriver.request.vehicleInfo.comment &&
                            patchDocument(
                              DriverRequestDocumentTypeEnum.vehicleInfo,
                              undefined,
                              e.target.value
                            );
                        }}
                        placeholder={
                          requestWithDriver.request.vehicleInfo.comment
                        }
                      />
                    </VStack>
                  ) : null}
                </VStack>
              </DashboardPanel>
            </GridItem>
          </Grid>
          <Stack
            flexDirection={isSmallScreen ? "column" : "row"}
            justifyContent="flex-end"
            w="90%"
            mb={5}
          >
            <Button
              bg={zubeColors.zubeGreen}
              borderRadius={20}
              onClick={() => patchGlobalStatus(VerificationStatusEnum.APPROVED)}
            >
              Aprobar solicitud
            </Button>
            <Button
              bg="red"
              borderRadius={20}
              onClick={() => patchGlobalStatus(VerificationStatusEnum.REJECTED)}
            >
              Rechazar solicitud
            </Button>
            <Button
              _hover={{ bg: zubeColors.zubePurple.light }}
              bg={zubeColors.zubePurple.lighter}
              borderRadius={20}
              disabled={true}
              onClick={() =>
                patchGlobalStatus(VerificationStatusEnum.APPOINTMENT)
              }
            >
              Agendar cita
            </Button>
            <Button
              bg={zubeColors.locationBlue}
              borderRadius={20}
              disabled={true}
              onClick={enableDriver}
            >
              Habilitar conductor
            </Button>
          </Stack>
        </VStack>
      ) : null}
    </PageContainer>
  );
};
