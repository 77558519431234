import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { type ComponentProps } from "react";

interface IZubeModalProps extends ComponentProps<typeof Modal> {
  contentHeightInPercentage?: string;
  title: string;
}

export const ZubeModal: React.FC<IZubeModalProps> = (props): JSX.Element => {
  const { children, title, ...rest } = props;

  return (
    <Modal {...rest} isCentered>
      <ModalOverlay />
      <ModalContent alignItems="center">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        {children}
      </ModalContent>
    </Modal>
  );
};
