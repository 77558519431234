import {
  Button,
  HStack,
  Select,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { isEmpty, isNil } from "lodash/fp";
import { zubeColors } from "styles/colors";
import { InputField } from "components/inputs";
import {
  IUser,
  UserPayloadType,
} from "services/resources/firebase/user/types.d";
import { planResources } from "services/resources/firebase/plan";
import { IPlan } from "services/resources/firebase/plan/types.d";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import {
  INITIAL_VALUES,
  PartnerFormValidationSchema,
} from "./helpers/form-helpers";

interface IPartnerFormProps extends StackProps {
  /**
   * Function for handling the form submission.
   */
  handleOnSubmit: (
    payload: UserPayloadType & { password: string }
  ) => Promise<void>;
  /**
   * Function triggered when a screen change was required.
   */
  onScreenChange?: () => void;
  /**
   * Route from which the form was called.
   */
  origin?: string;
  /**
   * Initial values for the form.
   */
  previousValues?: IUser & { password: string };
}

export const PartnerForm: React.FC<IPartnerFormProps> = (props) => {
  const { handleOnSubmit, onScreenChange, previousValues, ...rest } = props;
  const { token } = useSelector(getUser);
  const [plans, setPlans] = useState<IPlan[]>([]);

  const handleOnRetrievePlans = async (): Promise<void> => {
    try {
      const { data } = await planResources.getAll(token as string);
      if (!isNil(data)) setPlans(data);
    } catch (e) {
      console.log("Error-handleOnRetrievePlans: ", e);
    }
  };

  useEffect(() => {
    handleOnRetrievePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={!isEmpty(previousValues) ? previousValues : INITIAL_VALUES}
      onSubmit={handleOnSubmit}
      validationSchema={PartnerFormValidationSchema(
        isEmpty(previousValues) ? true : false
      )}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isValid,
        setFieldValue,
        values,
      }) => (
        <VStack {...rest}>
          <InputField
            error={errors.fullName}
            label="Nombre"
            onChangeText={handleChange("fullName")}
            type="text"
            value={values.fullName}
          />
          <InputField
            error={errors.email}
            isDisabled={!isEmpty(previousValues)}
            label="Email"
            onChangeText={handleChange("email")}
            type="email"
            value={values.email}
          />
          <InputField
            error={errors.password}
            isDisabled={!isEmpty(previousValues)}
            label="Contraseña"
            onChangeText={handleChange("password")}
            type="password"
            value={values.password}
          />
          <Text alignSelf="flex-start" fontSize={14} fontWeight="bold">
            Plan
          </Text>
          <Select
            mb={4}
            onChange={(e) => {
              setFieldValue(
                "partnerPlan.id",
                e.target.selectedOptions[0].value
              );
            }}
            placeholder="Selecciona un plan"
            value={values.partnerPlan?.id}
          >
            {plans.map((plan) => (
              <option key={plan.id} value={plan.id}>
                {plan.name}
              </option>
            ))}
          </Select>
          <Text alignSelf="flex-start" fontSize={14} fontWeight="bold">
            Estatus de Pago
          </Text>
          <Select
            mb={4}
            onChange={(e) => {
              setFieldValue(
                "partnerPlan.status",
                e.target.selectedOptions[0].value
              );
            }}
            placeholder="Selecciona un estatus"
            value={values.partnerPlan?.status}
          >
            <option value="active">Activo</option>
            <option value="pending">Pendiente</option>
          </Select>
          <HStack w="90%" mb={5}>
            <Button
              bg="white"
              borderRadius="full"
              borderColor={zubeColors.zubeOrange.light}
              borderWidth={1}
              onClick={onScreenChange}
              w="100%"
              color={zubeColors.zubeOrange.light}
            >
              Cancelar
            </Button>
            <Button
              bg={zubeColors.zubeOrange.light}
              borderRadius="full"
              isDisabled={!isEmpty(errors)}
              justifyContent="center"
              onClick={() => {
                if (isValid) handleSubmit();
              }}
              w="100%"
              color="white"
            >
              {!isEmpty(previousValues) ? "Actualizar" : "Crear"}
            </Button>
          </HStack>
        </VStack>
      )}
    </Formik>
  );
};

PartnerForm.defaultProps = {
  flex: 1,
  justifyContent: "center",
  spacing: 3,
  w: "90%",
};
