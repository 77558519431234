import {
  Button,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import {
  DashboardPanel,
  DashboardTable,
  PageContainer,
  ZubeModal,
} from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { isEmpty, isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import {
  IUser,
  UserPayloadType,
} from "services/resources/firebase/user/types.d";
import { IPlan } from "services/resources/firebase/plan/types.d";
import { planResources } from "services/resources/firebase/plan";
import { authResources } from "services/resources/firebase/auth";
import { userResources } from "services/resources/firebase/user";
import { useSmallScreen } from "hooks/layout/dimension-hooks";
import { zubeColors } from "styles/colors";
import { PartnerForm } from "components/forms";

export const Partners: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);
  const isSmallScreen = useSmallScreen();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formType, setFormType] = useState<string>("");
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [partners, setPartners] = useState<IUser[]>([]);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<IUser>({} as IUser);

  const getStatusLabel = (status: string | undefined): string => {
    switch (status) {
      case "active":
        return "Activo";
      case "pending":
        return "Pendiente";
      default:
        return "";
    }
  };

  const getPlanLabel = (planId: string | undefined): string => {
    const plan = plans.find((plan) => plan.id === planId);
    return !isNil(plan) ? plan.name : "";
  };

  const handleOnRetrievePlans = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await planResources.getAll(token as string);
      if (!isNil(data)) setPlans(data);
    } catch (e) {
      console.log("Error-handleOnRetrievePlans: ", e);
    }
    setLoadingModalVisible(false);
  };

  const handleOnRetrievePartners = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await userResources.getPartners(token as string);
      if (!isNil(data)) setPartners(data.filter((user) => user.email));
    } catch (e) {
      console.log("Error-handleOnRetrievePartners: ", e);
    }
    setLoadingModalVisible(false);
  };

  /**
   * Function to handle the creation and edition of partners.
   * @param partnerPayload
   */
  const handleOnSubmitPartner = async (
    partnerPayload: UserPayloadType & { password: string }
  ): Promise<void> => {
    onClose();
    setLoadingModalVisible(true);
    const { password, ...passwordLessPayload } = partnerPayload;
    try {
      if (!isEmpty(selectedPartner)) {
        await userResources.patchPartner(
          selectedPartner.id,
          passwordLessPayload,
          token as string
        );
      } else {
        await authResources.signUpWithEmailAndPassword({
          email: partnerPayload.email,
          password: partnerPayload.password,
        });
        await userResources.createPartner(passwordLessPayload, token as string);
      }
    } catch (e) {
      console.log("Error-handleOnSubmitPartner: ", e);
      window.alert("Ocurrió un error al crear/editar el partner.");
    } finally {
      setFormType("");
      setSelectedPartner({} as IUser);
      handleOnRetrievePartners();
      setLoadingModalVisible(false);
    }
  };

  const handleOnDeletePartner = async (userId: string): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      await userResources.delete(userId, token as string);
    } catch (e) {
      console.log("Error-handleOnDeletePartner: ", e);
      window.alert("Ocurrió un error al eliminar el partner.");
    } finally {
      handleOnRetrievePartners();
      setLoadingModalVisible(false);
    }
  };

  const columns: ITypedListTableColumn<IUser>[] = [
    {
      accessorKey: "name",
      header: "Nombre",
      cell: ({ row }) => <Text>{row.original.fullName}</Text>,
    },
    {
      header: "Email",
      cell: ({ row }) => <Text>{row.original.email}</Text>,
    },
    {
      header: "Plan",
      cell: ({ row }) => (
        <Text>{getPlanLabel(row.original.partnerPlan?.id)}</Text>
      ),
    },
    {
      header: "Estatus de Pago",
      cell: ({ row }) => (
        <Text>{getStatusLabel(row.original.partnerPlan?.status)}</Text>
      ),
    },
    {
      header: "Opciones",
      cell: ({ row }) => (
        <HStack>
          <Button
            color="white"
            bg={zubeColors.zubeGreen}
            onClick={() => {
              setSelectedPartner(row.original);
              setFormType("edit");
              onOpen();
            }}
            rounded="full"
            fontSize={12}
          >
            Editar
          </Button>
          <Button
            bg="red"
            color="white"
            fontSize={12}
            onClick={() => handleOnDeletePartner(row.original.id)}
            rounded="full"
          >
            Eliminar
          </Button>
        </HStack>
      ),
    },
  ];

  useEffect(() => {
    handleOnRetrievePlans();
    handleOnRetrievePartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer loadingModalVisible={loadingModalVisible}>
      <ZubeModal
        isOpen={isOpen}
        onClose={() => {
          setSelectedPartner({} as IUser);
          onClose();
        }}
        title={formType === "create" ? "Crear Partner" : "Editar Partner"}
      >
        <PartnerForm
          handleOnSubmit={handleOnSubmitPartner}
          onScreenChange={() => {
            setSelectedPartner({} as IUser);
            onClose();
          }}
          previousValues={
            !isEmpty(selectedPartner)
              ? { ...selectedPartner, password: "" }
              : ({} as IUser & { password: string })
          }
        />
      </ZubeModal>
      <HStack justifyContent="flex-end" w="95%">
        <Button
          bg={zubeColors.zubeOrange.light}
          borderRadius="full"
          color="white"
          mt={5}
          onClick={() => {
            setFormType("create");
            onOpen();
          }}
        >
          Registrar Partner
        </Button>
      </HStack>
      {isSmallScreen ? (
        <VStack alignItems="center" justifyContent="center" my={5}>
          <Grid gap={5} templateColumns="repeat(1, 1fr)" w="90%">
            {partners.map((partner, index) => (
              <GridItem key={index}>
                <DashboardPanel
                  label={partner.fullName}
                  rightElement={
                    <HStack>
                      <Button
                        color="white"
                        bg={zubeColors.zubeGreen}
                        onClick={() => {
                          setSelectedPartner(partner);
                          setFormType("edit");
                          onOpen();
                        }}
                        rounded="full"
                        fontSize={12}
                      >
                        Editar
                      </Button>
                      <Button
                        bg="red"
                        color="white"
                        fontSize={12}
                        onClick={() => handleOnDeletePartner(partner.id)}
                        rounded="full"
                      >
                        Eliminar
                      </Button>
                    </HStack>
                  }
                >
                  <VStack w="100%">
                    {[
                      {
                        label: "Email",
                        value: partner.email,
                      },
                      {
                        label: "Plan",
                        value: getPlanLabel(partner.partnerPlan?.id),
                      },
                      {
                        label: "Estatus de Pago",
                        value: getStatusLabel(partner.partnerPlan?.status),
                      },
                    ].map((item) => (
                      <>
                        <Divider />
                        <HStack fontSize={12} spacing="auto" my={3} w="90%">
                          <Text fontWeight="bold" w="35%">
                            {item.label}
                          </Text>
                          <Text>{item.value}</Text>
                        </HStack>
                      </>
                    ))}
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      ) : (
        <Center bg="transparent" w="100%" h="90%" alignSelf="center">
          <Center bg="white" w="90%" h="90%" borderRadius={5}>
            {columns.length > 0 ? (
              <DashboardTable
                data={partners}
                columns={columns as ColumnDef<object>[]}
                w="90%"
                h="90%"
                py={5}
              />
            ) : null}
          </Center>
        </Center>
      )}
    </PageContainer>
  );
};
