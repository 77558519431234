export const formatMoneyAmount = (number: number) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

export const getHeaderName = (isSmallScreen: boolean = false): string => {
  const path = window.location.pathname;
  if (path.includes("user"))
    return isSmallScreen ? "Usuarios" : "Usuarios del sistema";
  if (path.includes("driver")) return "Conductores";
  if (path.includes("partners")) return "Partners";
  if (path.includes("requests") || path.includes("review-request"))
    return "Solicitudes";
  if (path.includes("fares")) return "Tarifas";
  if (path.includes("plans")) return "Planes";
  if (path.includes("dashboard")) return "Dashboard";
  return "Dashboard";
};
