import { IFirebaseResource } from "../types.d";
import { IUser } from "../user/types.d";

export enum DriverTypeEnum {
  PRIVATE = "private",
  PUBLIC = "public",
  UNSELECTED = "unselected",
}

export enum VerificationStatusEnum {
  PENDING = "pending",
  SUBMITTED = "submitted",
  REJECTED = "rejected",
  APPROVED = "approved",
  APPOINTMENT = "appointment",
  COMPLETED = "completed",
}

export enum TaxiOrgEnum {
  RTM_S = "RTM_S",
  RTM_M = "RTM_M",
  RTM_A = "RTM_A",
  UNSELECTED = "UNSELECTED",
}

// Both keys and values will be used for calling the requestResources
// methods, so no capital letters are used for the keys
export enum DriverRequestDocumentTypeEnum {
  controlCard = "control-card",
  idCard = "id-card",
  driverLicense = "driver-license",
  insurance = "insurance",
  circulationCard = "circulation-card",
  vehicleInfo = "vehicle-info",
}

export interface IVehicleInfo {
  /**
   * The registered id that identifies the vehicle in the Mexican Movility
   * Secretary.
   */
  plates: string;
  /**
   * The brand-specific model that identifies the vehicle.
   */
  model: string;
  /**
   * The manufacturer brand that built the vehicle.
   */
  brand: string;
  /**
   * In which year was the vehicle built at.
   */
  year: string;
  /**
   * URL that points to the firebase storage place where the vehicle picture
   * has been stored.
   */
  picture: string;
}

export interface IDoubledSidedPicture {
  /**
   * The URL that contains the stored document (for the
   * front side).
   */
  front: string;
  /**
   * The URL that contains the stored document (for the
   * back side).
   */
  back: string;
}

// deprecated, will remove
export interface IDriverRequestPayload {
  /**
   * Either if the driver is public or private.
   */
  driverType: DriverTypeEnum;
  /**
   * To which taxi organization does the driver belongs to.
   */
  organization?: TaxiOrgEnum;
  /**
   * URL that points to the firebase storage place where the Mexican Id picture
   * has been stored.
   */
  idCard: IDoubledSidedPicture;
  /**
   * URL that points to the firebase storage place where the Mexican driver
   * license picture has been stored.
   */
  driverLicense: IDoubledSidedPicture;
  /**
   * URL that points to the firebase storage place where the automovile
   * insurance picture has been stored.
   */
  insurance: string;
  /**
   * URL that points to the firebase storage place where the automovile
   * insurance picture has been stored.
   */
  circulationCard?: string;
  /**
   * Information that identifies the driver's vehicle, as specific as needed.
   */
  vehicleInfo: IVehicleInfo;
}

export interface IVerifyingStage {
  /**
   * The status of the verification of a given document.
   */
  status: VerificationStatusEnum;
  /**
   * Comments that complement the verification stage of a
   * given document. Can provide extra information of the
   * rejection of a document, for example.
   */
  comment?: string;
}

export interface IVerifyingDocument<DocType> extends IVerifyingStage {
  /**
   * The document type that was uploaded to the request.
   * Sometimes is about a single URL that point to the stored
   * resource, and sometimes is about front and back cases.
   * Applies for most of stages but not for all.
   */
  document: DocType;
  /**
   * Date when the document is set to be expired (not valid anymore).
   */
  expirationDate: Timestamp | Date;
}

export interface IVerifyingVehicleForm<DocType>
  extends Omit<IVerifyingDocument<DocType>, "expirationDate"> {
  /**
   * The form that contains vehicle info such as brand, model, etc.
   */
  form: IVehicleInfo;
}

export interface IRequestFieldStage<FieldType>
  extends Omit<IVerifyingStage, "comment"> {
  /**
   * Actual value of the type which is not a form or a
   * document (simple field) that will be stored.
   */
  field: FieldType;
}

export interface IDriverRequest extends IFirebaseResource {
  /**
   * To be documented.
   */
  controlCard?: IVerifyingDocument<string>;
  /**
   * Either if the driver is public or private.
   */
  driverType: IRequestFieldStage<DriverTypeEnum>;
  /**
   * To which taxi organization does the driver belongs to.
   */
  organization?: IRequestFieldStage<string>;
  /**
   * Mexican Id picture stage.
   */
  idCard: IVerifyingDocument<IDoubledSidedPicture>;
  /**
   * Mexican driver license stage.
   */
  driverLicense: IVerifyingDocument<IDoubledSidedPicture>;
  /**
   * Automovile insurance stage.
   */
  insurance: IVerifyingDocument<string>;
  /**
   * Circulation card stage.
   */
  circulationCard?: IVerifyingDocument<string>;
  /**
   * Driver's vehicle stage.
   */
  vehicleInfo: IVerifyingVehicleForm<string>;
  /**
   * Id that corresponds to the driver which is making the
   * current request.
   */
  driverId: string;
  /**
   * Status that corresponds to the request as a whole.
   */
  status: VerificationStatusEnum;
  /**
   * To be documented.
   */
  vehicleCode?: string;
}

export interface IRequestWithDriver {
  request: IDriverRequest;
  driver: IUser;
}
