import {
  Box,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import {
  DashboardPanel,
  DashboardTable,
  PageContainer,
} from "components/elements";
import { ITypedListTableColumn } from "components/elements/DashboardTable/types.d";
import { format } from "date-fns";
import { isNil } from "lodash/fp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";
import { userResources } from "services/resources/firebase/user";
import { IUser } from "services/resources/firebase/user/types.d";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useSmallScreen } from "hooks/layout/dimension-hooks";

export const Drivers: React.FC = (): JSX.Element => {
  const { token } = useSelector(getUser);
  const isSmallScreen = useSmallScreen();
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<boolean>(false);
  const [drivers, setDrivers] = useState<IUser[]>([]);

  const columns: ITypedListTableColumn<IUser>[] = [
    {
      accessorKey: "img",
      header: "Imagen",
      cell: ({ row }) => (
        <Box maxH="70px">
          {!isNil(row.original.img) ? (
            <Image src={row.original.img} h="70px" />
          ) : (
            <Text>Sin imagen</Text>
          )}
        </Box>
      ),
    },
    {
      accessorKey: "fullName",
      header: "Nombre",
      cell: ({ row }) => <Text>{row.original.fullName}</Text>,
    },
    {
      header: "Datos de contacto",
      cell: ({ row }) => (
        <VStack alignItems="flex-start">
          <HStack>
            <MdEmail />
            <Text>{row.original.email}</Text>
          </HStack>
          <HStack>
            <FaPhoneAlt />
            <Text>{row.original.mobilePhoneNumber}</Text>
          </HStack>
        </VStack>
      ),
    },
    {
      accessorKey: "created",
      header: "Fecha de registro",
      cell: ({ row }) => (
        <Text>
          {format(
            new Date(row.original.created._seconds * 1000),
            "yyyy-MM-dd HH:mm:ss"
          )}
        </Text>
      ),
    },
  ];

  const handleOnDriversGet = async (): Promise<void> => {
    setLoadingModalVisible(true);
    try {
      const { data } = await userResources.getDrivers(token as string);
      if (!isNil(data)) {
        // filter out deleted drivers (info swiped)
        setDrivers(data.filter((user) => user.email));
      }
    } catch (e) {
      console.log("Error-handleOnDriversGet: ", e);
    }
    setLoadingModalVisible(false);
  };

  useEffect(() => {
    handleOnDriversGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer loadingModalVisible={loadingModalVisible}>
      {isSmallScreen ? (
        <VStack alignItems="center" justifyContent="center" my={5}>
          <Grid gap={5} templateColumns="repeat(1, 1fr)" w="90%">
            {drivers.map((driver, index) => (
              <GridItem key={index}>
                <DashboardPanel
                  label={driver.fullName}
                  rightElement={<Image src={driver.img} h="30px" />}
                >
                  <VStack w="100%">
                    {[
                      {
                        label: "Email",
                        value: driver.email,
                      },
                      {
                        label: "Teléfono",
                        value: driver.mobilePhoneNumber,
                      },
                      {
                        label: "Registro",
                        value: format(
                          new Date(driver.created._seconds * 1000),
                          "yyyy-MM-dd HH:mm a"
                        ),
                      },
                    ].map((item) => (
                      <>
                        <Divider />
                        <HStack fontSize={12} spacing="auto" my={3} w="90%">
                          <Text fontWeight="bold" w="35%">
                            {item.label}
                          </Text>
                          <Text>{item.value}</Text>
                        </HStack>
                      </>
                    ))}
                  </VStack>
                </DashboardPanel>
              </GridItem>
            ))}
          </Grid>
        </VStack>
      ) : (
        <Center bg="transparent" w="100%" h="100%" alignSelf="center">
          <Center bg="white" w="90%" h="90%" borderRadius={5}>
            {columns.length > 0 ? (
              <DashboardTable
                data={drivers}
                columns={columns as ColumnDef<object>[]}
                w="90%"
                h="90%"
                py={5}
              />
            ) : null}
          </Center>
        </Center>
      )}
    </PageContainer>
  );
};
