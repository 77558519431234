import {
  Box,
  BoxProps,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BsSpeedometer } from "react-icons/bs";
import { FaClipboard, FaRoad } from "react-icons/fa";
import { FaChartLine, FaHandshakeSimple, FaUsers } from "react-icons/fa6";
import { BiSolidUserBadge } from "react-icons/bi";
import { MdAttachMoney } from "react-icons/md";
import { useSelector } from "react-redux";
import { getUser } from "redux-service/slices";

export const NavMenu: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props;
  const userData = useSelector(getUser);
  const navigate = useNavigate();

  return (
    <Box {...rest}>
      <Menu>
        <MenuButton as={Button} bg="transparent" fontSize={18} my={5}>
          {children}
        </MenuButton>
        <MenuList bg="white">
          <MenuItem
            bg="white"
            color="black"
            onClick={() => navigate("/dashboard")}
            icon={<BsSpeedometer size={24} />}
          >
            Dashboard
          </MenuItem>
          {userData.isAdmin ? (
            <>
              <MenuDivider bg="black" />
              <MenuItem
                icon={<FaClipboard size={24} />}
                bg="white"
                color="black"
                onClick={() => navigate("/requests")}
              >
                Solicitudes
              </MenuItem>
              <MenuDivider bg="black" />
              <MenuItem
                bg="white"
                color="black"
                onClick={() => navigate("/drivers")}
                icon={<BiSolidUserBadge size={24} />}
              >
                Conductores
              </MenuItem>
              <MenuDivider bg="black" />
              <MenuItem
                bg="white"
                color="black"
                onClick={() => navigate("/users")}
                icon={<FaUsers size={24} />}
              >
                Usuarios
              </MenuItem>
              <MenuDivider bg="black" />
              <MenuItem
                bg="white"
                color="black"
                onClick={() => navigate("/partners")}
                icon={<FaHandshakeSimple size={24} />}
              >
                Partners
              </MenuItem>
              <MenuDivider bg="black" />
              <MenuItem
                bg="white"
                color="black"
                onClick={() => navigate("/dashboard")}
                icon={<FaRoad size={24} />}
              >
                Viajes
              </MenuItem>
              <MenuDivider bg="black" />
              <MenuItem
                bg="white"
                color="black"
                onClick={() => navigate("/fares")}
                icon={<MdAttachMoney size={24} />}
              >
                Tarifas
              </MenuItem>
              <MenuDivider bg="black" />
              <MenuItem
                bg="white"
                color="black"
                onClick={() => navigate("/plans")}
                icon={<FaChartLine size={24} />}
              >
                Planes
              </MenuItem>
            </>
          ) : null}
        </MenuList>
      </Menu>
    </Box>
  );
};
